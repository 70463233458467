import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

export default function Email() {
  const [showSendModal, setShowSendModal] = useState(false);
  const [showInboxModal, setShowInboxModal] = useState(false);
  const [showOutboxModal, setShowOutboxModal] = useState(false);

  const handleCloseSend = () => setShowSendModal(false);
  const handleCloseInbox = () => setShowInboxModal(false);
  const handleCloseOutbox = () => setShowOutboxModal(false);

  return (
    <>
      <Link className='fw-bold text-dark' to={"/"}>الرئيسية</Link>
      <Link className='text-muted mx-2 text-decoration-none mx-4'>الوارد</Link>

      <div className="mt-5 d-flex">
        <p className='mx-2'><i className="fa fa-cog" aria-hidden="true"></i></p>
        <p className='text-muted'>الوارد</p>
      </div>

      <div className='d-flex mt-5 '>
        <button className='btn bg-color p-1 px-3 text-white' onClick={() => setShowSendModal(true)}>ارسال <span><i class="fa-solid fa-plus"></i></span></button>
        <button className='btn bg-color p-1 px-3 text-white mx-3' onClick={() => setShowInboxModal(true)}>الوارد <span><i class="fa-solid fa-plus"></i></span></button>
        <button className='btn bg-color p-1 px-3 text-white' onClick={() => setShowOutboxModal(true)}>الصادر <span><i class="fa-solid fa-plus"></i></span></button>
      </div>

      {/* Send Modal */}
<Modal show={showSendModal} onHide={handleCloseSend} centered>
  <div className="modal-dialog modal-dialog-centered w-100">
    <div className="modal-content mx-4  border border-0">
      <Modal.Header closeButton>
        <Modal.Title>ارسال</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='pt-3 pb-4'>
    <div>
    <label className='fw-bold pb-0 mb-0'>البريد الاكتروني</label>
        <input type="text" className="form-control border-info mt-1 pb-" placeholder="البريد الاكتروني"/>
    </div>
    <div>
    <label className='fw-bold pb-0 mb-0'> الموضوع</label>
    <input type="text" className="form-control border-info mt-3 pb-" placeholder=" الموضوع"/>
    </div>
        <div>
        <label className='fw-bold pb-0 mb-0'> المحتوى</label>

            <textarea className='form-control border-info mt-3' placeholder="المحتوى"/>
        </div>
        <div className='mt-2'>
        <label className='fw-bold pb-0 mb-0'> ارسال ملف</label>

        <input type="file" className="form-control border-info mt-3 pb-" placeholder=" الموضوع"/>

        </div>
      </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
  
        <Button variant="primary" className='px-4 p' onClick={handleCloseSend}>
          Save
        </Button>
      </Modal.Footer>
    </div>
  </div>
</Modal>



      {/* <Modal show={showInboxModal} className='custom-modal' onHide={handleCloseInbox}>
      <div className="modal-dialog modal-dialog-centered w-100">
    <div className="modal-content mx-4  border border-0">
        <Modal.Header closeButton>
          <Modal.Title>الوارد </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Content for inbox modal...
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInbox}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseInbox}>
            Save Changes
          </Button>
        </Modal.Footer>
        </div>
        </div>
      </Modal>

      <Modal show={showOutboxModal} className='custom-modal' onHide={handleCloseOutbox}>
        <Modal.Header closeButton>
          <Modal.Title>Outbox Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Content for outbox modal...
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOutbox}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseOutbox}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
