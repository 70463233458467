import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Login() {
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // لتخزين اللغة المحددة

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value); // تحديث اللغة المحددة عند تغيير القيمة في عنصر select
  };
    const { t } = useTranslation(); // استخدام الدالة t لترجمة النصوص

  return (
    <>
      <div className="d-center flex-column vh-75 overflow-hidden">
        <div className=" mx-auto w-50  border border-2">
          <div className="p-3">
            <h4 className="text-center">تسجيل الدخول</h4>
            <input type="text" className="input-custom mt-3 w-100" />
            <input type="password" className="input-custom mt-3 w-100" />
            <button className="btn btn-dark mt-3 w-100 p-2 rounded-5">
           تسجيل دخول
            </button>
            <div className="d-flex justify-content-between mt-3">
              <Link className="pointer text-muted bg-white border-0  btn btn-primary d-center d-flex mt-3">
                نسيت كلمة المرور؟
              </Link>
              <div className="input-group-text">
                <p className="fw-bold text-muted mx-2">تذكرني</p>
                <input
                  className="form-check-input mt-0 border-red checkbox-red"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                />
              </div>
            </div>
            
            {/* إضافة عنصر select لتحديد اللغة */}
            {/* <select value={selectedLanguage} onChange={handleLanguageChange} className="form-select mt-3 w-100">
              <option value="en">English</option>
              <option value="ar">العربية</option>
            </select> */}

            {/* <Link className=' rounded-5 p-2 w-50 d-center btn btn-outline-primary mt-3 mx-6 '>  إنشاء حساب</Link> */}
          </div>
        </div>
      </div>
    </>
  );
}
