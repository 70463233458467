import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

export default function Daily_report() {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };


  const colums = [
    {
      name:" المهام",
      selector:row=>row.name,
      sortable:false
    },
    {
      name:"المدة ",
      selector:row=>row.email,
      sortable:true
    },

    {
      name:"	 الوقت الأضافى	",
      selector:row=>row.age,
      sortable:true
    },
    {
      name:"   الأجمالى ",
      selector:row=><div  className='text-center'>
      <i class="fa-solid fa-clock-rotate-left font-20"></i>
      </div>,
      sortable:true
    },
//  -------------------

//     {
//       name: "الحاله",
//       cell: row => (
//     <div className="w-50">

// <DropdownButton id="dropdown-basic-button" className="w-50" title=" الحاله">
//           <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
//           <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
//           <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
//         </DropdownButton>
//     </div>
//       ),
//       sortable: true,
//       selector:row=>row.age,
//     },
    // ------------------
    {
      name:"    التوقيت ",
      selector:row=><>
      <i class="fa-regular fa-clock font-20"></i>
      </>,
      sortable:true
    },
    {
      name:"     المتبقى ",
      selector:row=>row.enddate,
      sortable:true
    },
    {
      name:"      اسم المشروع	   ",
      selector:row=>row.counterhuor,
      sortable:true
    },
    // ------------------------------------------------------------------------
    {
      name:"      أختبار اول	 ",
      selector:row=>row.counterhuorr,
      sortable:true
    },
    {
      name:"        الحالة		 	   ",
      selector:row=>row.condition,
      sortable:true
    },
    {
      name:"        المسئول	  		 	   ",
      selector:row=>row.administrator,
      sortable:true
    },
    {
      name: "العمليات",
      cell: row => (
    <div className="w-50">

<DropdownButton id="dropdown-basic-button" className="w-50" title=" الحاله">
          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        </DropdownButton>
    </div>
      ),
      sortable: true,
      selector:row=>row.age,
    },

  ]
  const data = [
   
      {
        id:1,
        name:"سيستم النجار	",
        email:"1 ",
        age:"3",
        row:"Active	",
        startdate:"1/1/2002",
        enddate:"1/1/2002",
        datea:"1/1/2002",
        updautes:"0يوم",
        counterhuor:"صحة حلالك	",
        counterhuorr:"غير محدد",
        condition:"غير محدد",
        administrator:"Ahmed",
      },
 

  ]


 const [records, setrecords] = React.useState(data);

 function handleFilter(event){
  const newData = data.filter(row=>{
    return row.name.toLocaleUpperCase().includes(event.target.value.toLocaleUpperCase())
  })
  setrecords(newData)
 }
  return (
    <>
      <Link className='fw-bold text-dark' to={"/"}>الرئسيه</Link>
      <Link className='text-muted mx-2 text-decoration-none mx-4'>     المهام </Link>





      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">

      <input type="search" placeholder="search"   className='text-end form-control bg-gray mt-3 mb-3' onChange={handleFilter}/>
      </div>
      <DataTable
      columns={colums}
      data={records}
      selectableRows
      
      pagination="auto"
      ></DataTable>
      {/* ----------- end-Table ------------------------*/}

      


      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className='mx-5 pt-3 text-color '> إضافة بريد الكترونى</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div className="container">
          <div className="row mx-4">
          <div className=" col-4 mt-4">
<label className='w-100 '>     
البريد الالكترونى 
</label>
<input type="email" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='     
البريد الالكترونى
'/>
</div>
          <div className=" col-4 mt-4">
<label className='w-100 '>     
الموظف 
</label>
<select class="w-75 bg-gray mt-2" aria-label="Default select example">
  <option selected>Active (TeamWork)</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>
          <div className=" col-4 mt-4">
<label className='w-100 '>     

سبب المرسالة
</label>
<select class="w-75 bg-gray mt-2 w-75" aria-label="Default select example">
  <option selected>في حالة المهام علي العمليات</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>
            <div className="col-4"></div>
            <div className="col-4"></div>
          </div>
         </div>
        </Modal.Body>
        <Modal.Footer className='text-center d-center mb-4 mt-4'>
          <Button variant="secondary" className='px-4 mx-4' onClick={handleModalClose}>
            إغلاق
          </Button>
          <Button variant="primary" className='px-4' onClick={handleModalClose}>
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>





      <div className='mt-5'>
<ul class="nav nav-pills mb-3 d-flex justify-content-between w-75" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">الكل</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">قيد  الانتظار</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">قيد العمل</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-contact-tab1" data-bs-toggle="pill" data-bs-target="#pills-contact1" type="button" role="tab" aria-controls="pills-contact1" aria-selected="false">قيد المراجعه</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-contact-tab2" data-bs-toggle="pill" data-bs-target="#pills-contact2" type="button" role="tab" aria-controls="pills-contact2" aria-selected="false">ملاحظات المشروع</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-contact-tab3" data-bs-toggle="pill" data-bs-target="#pills-contact3" type="button" role="tab" aria-controls="pills-contact3" aria-selected="false">تم التنفيذ</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-contact-tab4" data-bs-toggle="pill" data-bs-target="#pills-contact4" type="button" role="tab" aria-controls="pills-contact4" aria-selected="false">المهام الحاليه</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-contact-tab5" data-bs-toggle="pill" data-bs-target="#pills-contact5" type="button" role="tab" aria-controls="pills-contact5" aria-selected="false">مهام اخري</button>
  </li>
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">...</div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
  <div class="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact-tab1">..21545.</div>
  <div class="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact-tab2">..21545.</div>
  <div class="tab-pane fade" id="pills-contact4" role="tabpanel" aria-labelledby="pills-contact-tab4">..21545.</div>
  <div class="tab-pane fade" id="pills-contact5" role="tabpanel" aria-labelledby="pills-contact-tab5">..21545.</div>
</div>
</div>

    </>
  );
}
