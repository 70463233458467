import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";

export default function Permissions() {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };
  const colums = [
    {
      name: " الأسم",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "المسئول ",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "	التاريخ",
      selector: (row) => row.datea,
      sortable: true,
    },
    {
      name: " التواجد فى المشاريع",
      selector: (row) => row.updaute,
      sortable: true,
    },

    {
      name: "الحاله",
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            className="w-50"
            title=" الحاله"
          >
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </div>
      ),
      sortable: true,
      selector: (row) => row.age,
    },
    {
      name: "العمليات",
      selector: (row) => row.Processes,
      sortable: true,
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            title="العمليات"
            className=""
          >
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1">
                <p>
                  <i class="fas fa-edit"></i>
                </p>
                <p className="mx-2">التحديث</p>
              </div>
            </Dropdown.Item>
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1 pb-2">
                <p className="mx-2">
                  <i class="fa-solid fa-x"></i>
                </p>
                <p>حذف</p>
              </div>
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];
  const data = [
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      Processes: "Process",
    },
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      start: "1/1/202",
      Processes: "Process",
    },
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      start: "1/1/202",
      Processes: "Process",
    },
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      start: "1/1/202",
      Processes: "Process",
    },
  ];
  const [records, setrecords] = React.useState(data);

  function handleFilter(event) {
    const newData = data.filter((row) => {
      return row.name
        .toLocaleUpperCase()
        .includes(event.target.value.toLocaleUpperCase());
    });
    setrecords(newData);
  }
  return (
    <>
      <Link className="fw-bold text-dark" to={"/"}>
        الرئسيه
      </Link>
      <Link className="text-muted mx-2 text-decoration-none mx-4">
        {" "}
        الأقسام{" "}
      </Link>

      <div className="mt-5 d-flex">
        <p className="mx-2">
          <i className="fa fa-cog" aria-hidden="true"></i>
        </p>
        <p className="text-muted"> الأقسام </p>
      </div>

      <div className="mt-4 d-flex">
        <button className="btn btn-danger px-3">
          {" "}
          <i className="fa-solid fa-xmark"></i> حذف{" "}
        </button>
        <button
          className="btn bg-color p-1 text-white mx-3"
          onClick={handleModalShow}
        >
          {" "}
          <i className="fa-solid fa-plus"></i> اضافه{" "}
        </button>
      </div>

      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">
        <input
          type="search"
          placeholder="search"
          className="text-end form-control bg-gray mt-3 mb-3"
          onChange={handleFilter}
        />
      </div>
      <DataTable
        columns={colums}
        data={records}
        selectableRows
        pagination="auto"
      ></DataTable>
      {/* ----------- end-Table ------------------------*/}

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-5 pt-3 text-color "> الأضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mx-4">
              <div className=" col-6 mt-4">
                <label className="w-100 ">الأسم</label>
                <input
                  type="email"
                  name=""
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"
                  placeholder="     
الأسم"
                />
              </div>
              <div className=" col-6 mt-4">
                <label className="w-100 ">Name</label>
                <input
                  type="email"
                  name=""
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"
                  placeholder="     
Name"
                />
              </div>

              <div className="mt-4">
                <h3 className="text-muted">الصلاحيات</h3>
                <div className="row mt-4">
                <div className="col-3">
  <div className="d-flex">
  <input type="checkbox" class="form-check-input mt-2 border border-2 border-black" id="exampleCheck5"/>
              <div class="mb-3 form-check w-50 mx-2">
    <label class="form-check-label" for="exampleCheck5"> finished_project</label>
  </div>
  </div>
              </div>
                  <div className="col-3">
  <div className="d-flex">
  <input type="checkbox" class="form-check-input mt-2 border border-2 border-black" id="exampleCheck2"/>
              <div class="mb-3 form-check w-50 mx-2">
    <label class="form-check-label" for="exampleCheck2"> projects_add</label>
  </div>
  </div>
              </div>
             
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck3"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck3">
                          {" "}
                          finished_project
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck5"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck5">
                          {" "}
                          finished_project
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck6"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck6">
                          {" "}
                          wait_project
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck7"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck7">
                          {" "}
                          future_project
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck8"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck8">
                          {" "}
                          users_add
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck9"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck9">
                          {" "}
                          projects_delete
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck10"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck10">
                          {" "}
                          projects_add
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck11"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck11">
                          {" "}
                          add_other_task
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck12"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck12">
                          {" "}
                          allprojects_view
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck13"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck13">
                          {" "}
                          company_serivces
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck14"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck14">
                          {" "}
                          teamwork
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck16"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck16">
                          {" "}
                          log_othertasks
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck20"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck20">
                          {" "}
                          log_task
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck15"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck15">
                          {" "}
                          permissions
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck133"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck33">
                          {" "}
                          log_othertasks
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck17"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck17">
                          {" "}
                          log_services
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck18"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck18">
                          {" "}
                          log_projects
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        class="form-check-input mt-2 border border-2 border-black"
                        id="exampleCheck19"
                      />
                      <div class="mb-3 form-check w-50 mx-2">
                        <label class="form-check-label" for="exampleCheck19">
                          {" "}
                          company_serivces
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center d-center mb-4 mt-4">
          <Button
            variant="secondary"
            className="px-4 mx-4"
            onClick={handleModalClose}
          >
            إغلاق
          </Button>
          <Button variant="primary" className="px-4" onClick={handleModalClose}>
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
