import React from 'react'
import { Link } from 'react-router-dom'

export default function AddTask() {
  return<>
      <Link className='fw-bold text-dark' to={"/"}>الرئيسية</Link>
      <Link className='text-muted mx-2 text-decoration-none mx-4'>  المهام</Link>

      <div className="mt-5 d-flex">
        <p className='mx-2'><i className="fa fa-cog" aria-hidden="true"></i></p>
        <p className='text-muted'>  المهام</p>
      </div>
  
  </>
}
