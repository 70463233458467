import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";

export default function Teamwork() {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };
  const colums = [
    {
      name: " الأسم",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "البريد الألكترونى ",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "	ايميل المرسلة	",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "   المسمى الوظيفى	",
      selector: (row) => row.row,
      sortable: true,
    },
    {
      name: "    التخصص		",
      selector: (row) => row.datea,
      sortable: true,
    },
    {
      name: "    اخر تسجيل الدخول		",
      selector: (row) => row.updaute,
      sortable: true,
    },

    {
      name: "الحاله",
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            className="w-50"
            title=" الحاله"
          >
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </div>
      ),
      sortable: true,
      selector: (row) => row.Processess,
    },
    {
      name: "العمليات",
      selector: (row) => row.Processes,
      sortable: true,
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            title="العمليات"
            className=""
          >
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1">
                <p>
                  <i class="fas fa-edit"></i>
                </p>
                <p className="mx-2">التحديث</p>
              </div>
            </Dropdown.Item>
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1 pb-2">
                <p className="mx-2">
                  <i class="fa-solid fa-x"></i>
                </p>
                <p>حذف</p>
              </div>
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];
  const data = [
    {
      id: 1,
      name: "Ahmed  Waled",
      email: "  .w@active4web.com	 ",
      age: "@active4web.com",
      row: "programmers",
      datea: "Web Design",
      updautee: "2024-04-17 09:21:14		",
      updaute: "2024-04-17 09:21:14		",
      
      Processes: "Process",
      Processess: "Process",
      Processes: "Process",
    },
    {
      id: 1,
      name: "mahmed  Waled",
      email: "  .w@active4web.com	 ",
      age: "@active4web.com",
      row: "programmers",
      datea: "Web Design",
      updautee: "2024-04-17 09:21:14		",
      updaute: "2024-04-17 09:21:14		",
      
      Processes: "Process",
      Processess: "Process",
      Processes: "Process",
    },
  
  ];
  const [records, setrecords] = React.useState(data);

  function handleFilter(event) {
    const newData = data.filter((row) => {
      return row.name
        .toLocaleUpperCase()
        .includes(event.target.value.toLocaleUpperCase());
    });
    setrecords(newData);
  }
  return (
    <>
      <Link className="fw-bold text-dark" to={"/"}>
        الرئسيه
      </Link>
      <Link className="text-muted mx-2 text-decoration-none mx-4">
        {" "}
        فريق العمل{" "}
      </Link>

      <div className="mt-5 d-flex">
        <p className="mx-2">
          <i className="fa fa-cog" aria-hidden="true"></i>
        </p>
        <p className="text-muted">  فريق العمل </p>
      </div>

      <div className="mt-4 d-flex">
        <button className="btn btn-danger px-3">
          {" "}
          <i className="fa-solid fa-xmark"></i> حذف{" "}
        </button>
        <button
          className="btn bg-color p-1 text-white mx-3"
          onClick={handleModalShow}
        >
          {" "}
          <i className="fa-solid fa-plus"></i> اضافه{" "}
        </button>
      </div>

      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">
        <input
          type="search"
          placeholder="search"
          className="text-end form-control bg-gray mt-3 mb-3"
          onChange={handleFilter}
        />
      </div>
      <DataTable
        columns={colums}
        data={records}
        selectableRows
        pagination="auto"
      ></DataTable>
      {/* ----------- end-Table ------------------------*/}

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-5 pt-3 text-color "> الأضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mx-4">
              <div className=" col-4 mt-4">
                <label className="w-100 ">صورة الموظف</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"
                  placeholder="     
الأسم"
                />
              </div>

              <div className=" col-4 mt-4">
                <label className="w-100 ">الإسم الاول</label>
                <input
                  type="text"
                  name=""
                  placeholder="الإسم الاول"
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"

                />
              </div>
              <div className=" col-4 mt-4">
                <label className="w-100 ">الأسم الثانى </label>
                <input
                  type="text"
                  name=""
                  placeholder="الأسم الثانى "
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"

                />
              </div>
              <div className=" col-4 mt-4">
                <label className="w-100 "> الأسم الأخير  </label>
                <input
                  type="text"
                  name=""
                  placeholder="الأسم  الأخير"
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"

                />
              </div>
              <div className=" col-4 mt-4">
                <label className="w-100 ">  البريد الألكترونى  </label>
                <input
                  type="email"
                  name=""
                  placeholder="البريد الألكترونى  "
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"

                />
              </div>
              <div className=" col-4 mt-4">
                <label className="w-100 ">   ايميل المرسلة  </label>
                <input
                  type="email"
                  name=""
                  placeholder=" ايميل المرسلة  "
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"

                />
              </div>
              <div className=" col-4 mt-4">
                <label className="w-100 ">    المسمى الوظيفى  </label>
                <select class="bg-gray w-75" aria-label="Default select example">
  <option selected>E-Marketing</option>
  <option value="1">sales</option>
  <option value="2">Testing </option>
  <option value="3">programmers</option>
  <option value="4">TeamWork</option>
  <option value="5">Web Design</option>
</select>
              </div>
              <div className=" col-4 mt-4">
                <label className="w-100 ">     التخصص  </label>
                <select class="bg-gray w-75" aria-label="Default select example">
  <option selected>Open this select menu</option>
  <option value="1">Social media</option>
  <option value="2">Testing </option>
  <option value="3">App Development</option>
  <option value="4">Backend Development</option>
  <option value="5">Web Design</option>
</select>
              </div>








              <div className=" col-4 mt-4">
                <label className="w-100 ">   
كلمة المرور   </label>
                <input
                  type="password"
                  name=""
                  placeholder="  password  "
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"

                />
              </div>

              <div className=" col-4 mt-4">
                <label className="w-100 ">   

                تاكيد كلمة المرور    
  </label>
                <input
                  type="password"
                  name=""
                  placeholder="  password  "
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"

                />
              </div>
          

          
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center d-center mb-4 mt-4">
          <Button
            variant="secondary"
            className="px-4 mx-4"
            onClick={handleModalClose}
          >
            إغلاق
          </Button>
          <Button variant="primary" className="px-4" onClick={handleModalClose}>
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
