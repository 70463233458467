import React, { createContext, useState, useContext } from 'react';

// إنشاء context
export const LanguageContext = createContext();

// تخزين لغة الافتراضية
const defaultLanguage = 'en';

// إنشاء مكون مزود
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage);

  // دالة لتغيير اللغة
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
  {children}
    </LanguageContext.Provider>
  );
};

// استخدام السياق في مكونات التطبيق
export const useLanguage = () => useContext(LanguageContext);
