import React from 'react'
import Navbar from '../Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Sidnar from '../Sidbar/Sidnar'


export default function Layout() {
  return <>
      <Sidnar/>

  <div className="container-fluid mx-auto ">
    <div className="row">
    <div className="col-2">   
           <Navbar/>
        </div>

      <div className="col-10 ">
        
       <div className='mx-4'>
       <Outlet></Outlet>
       </div>
      </div>

    </div>
  </div>
  <Footer/>


  </>
}
